import React, { useState } from 'react';
import './RsvpDropdown.css';

function RsvpDropdown(props) {
  const [selection, setSelection] = useState(parseInt(props.initialState)); // Initial selection state

  // Function to handle selection change
  const handleSelectionChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelection(selectedValue);
    props.onChangeCallback(selectedValue);
  };

  return (
    <div className='row content-center'>
      <div className='row'>
        <button className={selection === 1 ? 'rsvp-option rsvp-selected' : 'rsvp-option'} onClick={handleSelectionChange} value={1}>Going</button>
        <div className='spacer-w-4' />
        <button className={selection === 0 ? 'rsvp-option rsvp-selected' : 'rsvp-option'} onClick={handleSelectionChange} value={0}>Undecided</button>
        <div className='spacer-w-4' />
        <button className={selection === 2 ? 'rsvp-option rsvp-selected' : 'rsvp-option'} onClick={handleSelectionChange} value={2}>Not Going</button>
      </div>
    </div>
  );
}

export default RsvpDropdown;
