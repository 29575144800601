import React from 'react';

import './FancyHeader.css';

const FancyHeader = ({ headerName }) => {
    return (
        <div className='column'>
            <h1 className='rem-3 primary font-fancy fancy-header'>{headerName}</h1>
            <div className='column-divider' />
            <div className='spacer-h-50' />
        </div>
    );
}

export default FancyHeader;