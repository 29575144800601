import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import './OurStory.css'

const StoryItem = class {
    constructor(bold, normal) {
        this.bold = bold;
        this.normal = normal;
    }
};

let storyItems = [
    new StoryItem("October 2013.", "Met at The College of New Jersey, thanks to a mutual friend"),
    new StoryItem("January 2014.", "Met each other's families"),
    new StoryItem("January 2015.", "Made the relationship official"),
    new StoryItem("March 2017.", "Went to Mexico for our first vacation together"),
    new StoryItem("May 2017.", "Graduated TCNJ together"),
    new StoryItem("August 2018.", "Dom visited Croatia for the first time"),
    new StoryItem("April 2020.", "Dom started with Underdog"),
    new StoryItem("May 2020.", "Christine graduated from Columbia with her master's degree"),
    new StoryItem("July 2020.", "Adopted our kitties; Niko, Vana, and Mala"),
    new StoryItem("November 2020.", "Moved into our current apartment in Manhattan"),
    new StoryItem("September 2022.", "Did things our way and started wedding planning"),
    new StoryItem("July 2023.", "Officially engaged and visited the wedding venue to continue planning"),
    new StoryItem("February 2024.", "Made the marriage official"),
    new StoryItem("September 2024.", "Hosting a wedding celebration in Dubrovnik")
]

let storyItemsHr = [
    new StoryItem("Listopad 2013.", "Upoznali se na The College of New Jersey zahvaljujući zajedničkom prijatelju"),
    new StoryItem("Siječanj 2014.", "Upoznali su obitelji jedno drugoga"),
    new StoryItem("Siječanj 2015.", "Učinili svoju vezu službenom"),
    new StoryItem("Ožujak 2017.", "Otišli u Meksiko na svoj prvi zajednički odmor"),
    new StoryItem("Svibanj 2017.", "Diplomirali na TCNJ zajedno"),
    new StoryItem("Kolovoz 2018.", "Dom je prvi put posjetio Hrvatsku"),
    new StoryItem("Travanj 2020.", "Dom je počeo raditi u Underdogu"),
    new StoryItem("Svibanj 2020.", "Christine je diplomirala na Columbia fakultetu i stekla magistarsku diplomu"),
    new StoryItem("Srpanj 2020.", "Udomili naše mačkice; Niko, Vana i Mala"),
    new StoryItem("Studeni 2020.", "Preselili u naš trenutni stan na Manhattanu"),
    new StoryItem("Rujan 2022.", "Radili stvari na svoj način i počeli planirati vjenčanje"),
    new StoryItem("Srpanj 2023.", "Službeno se zaručili i posjetili vjenčani prostor za nastavak planiranja"),
    new StoryItem("Siječanj 2023.", "Planiranje službene ceremonije"),
    new StoryItem("Veljača 2024.", "Službeno sklopili brak"),
    new StoryItem("Rujan 2024.", "Organiziramo proslavu vjenčanja u Dubrovniku")
]

function OurStory(props) {
    let itemsForUi = props.isCroatianEnabled ? storyItemsHr : storyItems
    let timelineItems = itemsForUi.map((item, index) => {
        let name = index % 2 === 0 ? "end" : "start"
        let name2 = "rem-2".concat(" ", name)
        return (
            <VerticalTimelineElement
                className={name}
                contentStyle={{ background: 'var(--steeny-bone)', color: 'var(--steeny-brown)', padding: '0', boxShadow: 'none' }}
                contentArrowStyle={{ display: 'none' }}
                date=""
                dateClassName='hello'
                iconStyle={{ background: 'var(--steeny-bone)', color: '#fff' }}
            >
                <div className={name2}>
                    <strong>{item.bold}</strong>&nbsp;{item.normal}
                </div>
            </VerticalTimelineElement>
        );
    })
    return (
        <div class="timeline bkg-steeny-bone">
            <VerticalTimeline lineColor={'var(--steeny-brown)'}>
                {timelineItems}
            </VerticalTimeline>
        </div>
    );
}

export default OurStory;