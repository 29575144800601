import React, { useState } from 'react';
import RoundedImage from '../image/RoundedImage';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import './ImageSlider.css'

const ImageSlider = ({ images, onImageClicked }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  return (
    <div className='box'>
      <RoundedImage url={images[currentIndex]} alt="image" onImageClicked={() => onImageClicked(currentIndex)} />
      {images.length > 1 && <div className='prev-button' onClick={prevImage}><FaArrowLeft /></div>}
      {images.length > 1 && <div className='next-button' onClick={nextImage}><FaArrowRight /></div>}
    </div>

  );
};

export default ImageSlider;
