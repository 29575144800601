import React from 'react';

import './MobilePage.css'

const MobilePage = (props) => {
  return (
    <div className='page column'>
        <img src='/croatia.png' width="100" height="100" alt="Croatia" />
        <div className='spacer-h-50'></div>
        <h1 className='primary rem-2'>Please view on a desktop for optimal experience.</h1>
        <div className='spacer-h-25'></div>
        <h2 className='primary'>I did not have enough time to make it properly mobile-friendly.</h2>
        <div className='spacer-h-25'></div>
        <h4 className='primary'>Thanks! --Dom</h4>
        <div className='spacer-h-50'></div>
        <h4 className='primary'>If you would like to proceed anyway...</h4>
        <button className='proceed-button' onClick={() => {props.proceedAnyway()}}>Let's do this</button>
    </div>
  );
};

export default MobilePage;