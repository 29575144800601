import React, { useState } from 'react';

import './Dialog.css'

const Dialog = ({ title, description, onCancel, onSubmit, initialValue }) => {
    const [inputValue, setInputValue] = useState(initialValue);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(inputValue);
    };

    return (
        <div className="dialog-overlay">
            <div className="dialog">
                <h2>{title}</h2>
                <p>{description}</p>
                <input type="text" value={inputValue} onChange={handleInputChange} />
                <div className="dialog-buttons">
                    <button onClick={onCancel}>Cancel</button>
                    <div className='spacer-w-16'/>
                    <button className='submit' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
};

export default Dialog;