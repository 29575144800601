import './Sidebar.css';
import proof_1 from './../../proof/chatgpt_apology.png';
import proof_2 from './../../proof/chatgpt_amazing.png';
import proof_3 from './../../proof/chatgpt_rsvp.png';
import proof_4 from './../../proof/chatgpt_kotlin.png';
import proof_5 from './../../proof/chatgpt_star_rating.png';
import { useState } from 'react';
import ScrollableOverlay from './../overlays/ScrollableOverlay';
import Checkbox from '../checkbox/Checkbox';

function Sidebar(props) {
  let listItems = props.items.map((item, index) => {
    let name = item.isSelected ? "active" : ""
    return <li className={name} key={index} onClick={() => props.onClick(item)}>{item.title}</li>
  })

  const proof = [
    proof_1, proof_5, proof_2, proof_3, proof_4
  ]
  const [showProof, setShowProof] = useState(false);

  const handleClickableTextClick = () => {
    setShowProof(true)
  };

  let labelText = props.isCroatianEnabled ? "Disable Croatian" : "Uključi Hrvatski"
  return (
    <div className="sidebar">
      <h1>Christine & Domenic</h1>
      <div className='sep' />
      <ul>{listItems}</ul>
      <div className='sep' />
      <footer>Made in NYC with <a onClick={handleClickableTextClick} className="clickable-text">ChatGPT</a></footer>
      <Checkbox label={labelText} checked={props.isCroatianEnabled} onChange={() => props.onCheckboxClick(!props.isCroatianEnabled)} />
      {showProof && <ScrollableOverlay paths={proof} startIndex={0} onClose={() => { setShowProof(false) }} />}
    </div>
  );
}

export default Sidebar;