import React, { useState, useEffect, useCallback } from "react";
import Overlay from "./Overlay";
import './ScrollableOverlay.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ScrollableOverlay = ({
    paths, startIndex, onClose
}) => {

    const [currentIndex, setCurrentIndex] = useState(startIndex)
    const [currentImagePath, setCurrentImagePath] = useState(paths[currentIndex])
    const [canMoveLeft, setCanMoveLeft] = useState(startIndex !== 0)
    const [canMoveRight, setCanMoveRight] = useState(startIndex !== paths.length - 1)

    const onRightButtonClicked = () => {
        if (currentIndex < paths.length - 1) {
            setCurrentIndex(prevIndex => prevIndex + 1);
        }
    }

    const onLeftButtonClicked = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevIndex => prevIndex - 1);
        }
    }

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'ArrowRight') {
            onRightButtonClicked()
        } else if (event.key === 'ArrowLeft') {
            onLeftButtonClicked()
        }
    }, [currentIndex, paths.length])

    useEffect(() => {
        setCanMoveLeft(currentIndex !== 0)
        setCanMoveRight(currentIndex !== paths.length - 1)
    }, [currentIndex])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        setCurrentImagePath(paths[currentIndex]);
    }, [currentIndex, currentImagePath]);

    return (
        <div className="scroling-overlay">
            <Overlay path={currentImagePath} onClose={onClose} />
            {canMoveLeft && <div className={`arrow-button left`} onClick={onLeftButtonClicked}><FaArrowLeft /></div>}
            {canMoveRight && <div className={`arrow-button right`} onClick={onRightButtonClicked}><FaArrowRight /></div>}
        </div>
    );
}

export default ScrollableOverlay;