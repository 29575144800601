import './HomePage.css'

function HomePage(props) {
    let header = props.isCroatianEnabled ? "Ženimo se!" : "We're getting married!"
    let date = props.isCroatianEnabled ? "14. rujna 2024." : "September 14, 2024"
    let location = props.isCroatianEnabled ? "Hotel Dubrovnik Palace • Dubrovnik, Hrvatska" : "Hotel Dubrovnik Palace • Dubrovnik, Croatia"
    return (
        <div className="HomePage">
            {
                window.innerWidth > 768 && <div className='card-text text-align-end bottom-right'>
                    <h1 className='rem-4'>{header}</h1>
                    <p className='rem-3'>{date}</p>
                    <p className='rem-2'>{location}</p>
                </div>
            }
        </div>
    );
}

export default HomePage;