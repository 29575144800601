import React from 'react';

import './RadioButton.css'

function RadioButton({ checked, onChange }) {
    return (
        <input
            className='radioButton'
            type="radio"
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
        />
    );
}

export default RadioButton;