import React, { useEffect } from 'react';

import './Overlay.css'

const Overlay = ({ path, onClose }) => {
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [onClose]);

    return (
        <div className="overlay" onClick={() => onClose()}>
            <button className="close-button" onClick={() => onClose()}>close (esc)</button>
            <img src={path} type="image/png" onClick={(e) => e.stopPropagation()} />
        </div>
    );
};

export default Overlay;