import React from 'react';

import './AccommodationsPage.css';
import StarRating from '../star_rating/StarRating';

const Section = class {
    constructor(title, hotelInfo) {
        this.title = title;
        this.hotelInfo = hotelInfo;
    }
}

const HotelInfo = class {
    constructor(name, address, url, rating, contact, isPrimary) {
        this.name = name;
        this.address = address;
        this.url = url;
        this.rating = rating;
        this.contact = contact;
        this.isPrimary = isPrimary;
    }
}

const Contact = class {
    constructor(name, email) {
        this.name = name;
        this.email = email;
    }
}

let sections = [
    new Section(
        "All wedding events will occur at...",
        new HotelInfo(
            "Hotel Dubrovnik Palace",
            "Masarykov put 20, 20000, Dubrovnik, Croatia",
            "https://www.adriaticluxuryhotels.com/hotel-dubrovnik-palace/",
            5,
            new Contact(
                "Ivana Obradovic",
                "ivana.obradovic@alh.hr"
            ),
            true
        )
    ),
    new Section(
        "A nearby hotel...",
        new HotelInfo(
            "Hotel Kompas",
            "Ul. kardinala Stepinca 21, 20000, Dubrovnik, Croatia",
            "https://www.adriaticluxuryhotels.com/hotel-kompas-dubrovnik/",
            4,
            new Contact(
                "Ivana Obradovic",
                "ivana.obradovic@alh.hr"
            ),
            false
        )
    )
]

let sectionsHr = [
    new Section(
        "Svi događaji vjenčanja odvijat će se u...",
        new HotelInfo(
            "Hotel Dubrovnik Palace",
            "Masarykov put 20, 20000, Dubrovnik, Croatia",
            "https://www.adriaticluxuryhotels.com/hotel-dubrovnik-palace/",
            5,
            new Contact(
                "Ivana Obradovic",
                "ivana.obradovic@alh.hr"
            ),
            true
        )
    ),
    new Section(
        "Susjedni hotel...",
        new HotelInfo(
            "Hotel Kompas",
            "Ul. kardinala Stepinca 21, 20000, Dubrovnik, Croatia",
            "https://www.adriaticluxuryhotels.com/hotel-kompas-dubrovnik/",
            4,
            new Contact(
                "Ivana Obradovic",
                "ivana.obradovic@alh.hr"
            ),
            false
        )
    )
]

function buildHeaderUi(title) {
    return (<h1 className='rem-2 primary'><strong>{title}</strong></h1>);
}

function buildHotelInfoUi(hotelInfo) {
    return (
        <div className='column align-center'>
            <p className='rem-4 primary'>{hotelInfo.name}</p>
            <p className='rem-1_5 primary'>{hotelInfo.address}</p>
        </div>
    );
}

function buildSection(section) {
    const handleDivClick = () => {
        window.open(section.hotelInfo.url, '_blank'); // Open the URL in a new tab
    };
    const borderClassName = section.hotelInfo.isPrimary ? 'primary-border-full-selected' : 'primary-border-full';
    return (
        <a href={section.hotelInfo.url} target="_blank" rel="noopener noreferrer" className='zzzz no-underline-link'>
            <div onClick={handleDivClick} className={`column ${borderClassName} hotel-info-section center align-center`}>
                {buildHeaderUi(section.title)}
                <div className='spacer-h-50' />
                {buildHotelInfoUi(section.hotelInfo)}
                <StarRating rating={section.hotelInfo.rating} />
                <div className='spacer-h-10' />
            </div>
        </a>
    );
}

const AccommodationsPage = (props) => {
    let sectionArray = props.isCroatianEnabled ? sectionsHr : sections
    let promoCodeLeft = props.isCroatianEnabled ? "Promotivni kod:" : "Promo code:"
    let promoCodeRight = props.isCroatianEnabled ? "važi do 13. lipnja 2024." : "valid until June 13, 2024"
    let promoCodeDescription = props.isCroatianEnabled ? "Promotivna šifra vrijedi za standardne sobe u oba hotela, od 13. do 16. rujna" : "The promo code is active for standard rooms at both hotels, for September 13th - 16th"
    let promoCodeDescription2 = props.isCroatianEnabled ? "Sve sobe rezervirane s promotivnom šifrom imat će balkon s pogledom na more i uključen doručak" : "All rooms booked with the promo code will have a balcony with sea views and breakfast included"
    let description = props.isCroatianEnabled ? "Za pomoć prilikom rezervacije, slobodno se obratite Ivani Obradović na ivana.obradovic@alh.hr" : "For any assistance while booking, please reach out to Ivana Obradovic at ivana.obradovic@alh.hr" 
    let pageUi = (
        <div className='column center align-center'>
            {buildSection(sectionArray[0])}
            <div className='spacer-h-16' />
            {buildSection(sectionArray[1])}
            <div className='spacer-h-50' />
            <p className='rem-3 primary text-center' dangerouslySetInnerHTML={{ __html: `${promoCodeLeft} <i><b>NikoMala</b></i> ${promoCodeRight}` }}></p>
            <div className='spacer-h-25' />
            <p className='rem-1_5 primary text-center'>{promoCodeDescription}</p>
            <div className='spacer-h-5' />
            <p className='rem-1_5 primary text-center'>{promoCodeDescription2}</p>
            <div className='spacer-h-50' />
            <p className='rem-1 primary text-center'><i>{description}</i></p>
            <div className='spacer-h-50' />
        </div>
    );

    return (
        <div className='page'>
            {pageUi}
        </div>
    );
}

export default AccommodationsPage;