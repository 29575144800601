import React, { useState } from "react";
import { FaCocktail, FaRegGem } from 'react-icons/fa'
import { GiPartyPopper } from 'react-icons/gi'
import { BsSunrise } from 'react-icons/bs'
import { TbBeach } from 'react-icons/tb'
import { IconContext } from 'react-icons'

import './WeddingEventsPage.css'
import FancyHeader from '../FancyHeader'
import RoundedImage from '../image/RoundedImage'
import Overlay from '../overlays/Overlay'
import ScrollableOverlay from "../overlays/ScrollableOverlay";
import ImageSlider from "../image_slider/ImageSlider";

const EventItem = class {
    constructor(title, time, location) {
        this.title = title;
        this.time = time;
        this.location = location;
    }
};

const Day = class {
    constructor(name, description, events, images) {
        this.name = name;
        this.description = description;
        this.events = events;
        this.images = images;
    }
}

let items = [
    new Day(
        "September 13, 2024",
        "You are invited to celebrate with us the evening before the wedding at Hotel Dubrovnik Palace! Unwind after your travels, meet other guests, and get ready for a weekend of celebrating. Come and go as you please! This is of course optional, but we hope to see you there! Dress code: casual neat.",
        [
            new EventItem("Welcome Party", "17:00-22:00", "Lenga Terrace, Hotel Dubrovnik")
        ],
        [
            "https://dubrovnik-palace.hotelmix.co.uk/data/Photos/OriginalPhoto/12771/1277134/1277134621/Hotel-Dubrovnik-Palace-Exterior.JPEG",
            "https://dubrovnik-palace.at-hotels.com/data/Photos/OriginalPhoto/12771/1277134/1277134336/photo-palace-20000-dubrovnik-5.JPEG"
        ]
    ),
    new Day(
        "September 14, 2024",
        "All wedding day events will take place at Hotel Dubrovnik Palace. See the Accommodations page for more! Please note- the locations within the hotel are all walkable. Staff will guide guests to each location and will also be available to support guests who require a more accessible route. Dress code: formal, beach formal.",
        [
            new EventItem("Ceremony", "16:00", "Lenga Terrace, Hotel Dubrovnik"),
            new EventItem("Cocktail Hour", "17:00", "Lenga Terrace, Hotel Dubrovnik"),
            new EventItem("Reception", "18:00", "Vala Beach Club, Hotel Dubrovnik"),
            new EventItem("Disco", "23:30", "Orlandinjo Night Club, Hotel Dubrovnik")
        ],
        [
            "https://images.squarespace-cdn.com/content/v1/5d67f54999e03b0001ecc78e/1594309883942-NZ3SQ4RFJSSRO6Y9M4QW/DJI_0047.jpg",
            "https://cdn.audleytravel.com/1050/749/79/7996227-hotel-dubrovnik-palace-dubrovnik.webp",
            "https://palace20000.thedubrovnikhotels.net/data/Imgs/OriginalPhoto/12771/1277134/1277134429/palace-20000-dubrovnik-img-6.JPEG",
            "https://palace20000.thedubrovnikhotels.net/data/Imgs/OriginalPhoto/12771/1277134/1277134852/palace-20000-dubrovnik-img-12.JPEG"
        ]
    ),
    new Day(
        "September 15, 2024",
        "You are invited to spend the day after the wedding relaxing with us on the beach! We will have private beds and cabanas right along the sea, with food and drink provided. Come and go as you please! This is of course optional, but we hope to see you there! Dress code: bathing suits and cover ups.",
        [
            new EventItem("Beach BBQ", "12:00", "Copacabana Beach")
        ],
        [
            "https://thebeachdubrovnik.com/cdn/shop/products/Copacabanabeachbaldahin.jpg?v=1659724370&width=1946",
            "https://thebeachdubrovnik.com/cdn/shop/files/Copacabanahome3.jpg?v=1658231088&width=1500",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/189524877.jpg?k=80fa68501f4915f002cfce4c59064620b06373283ed84ba1ae36c1474fbd4c1f&o=&hp=1"

        ]
    )
]

let itemsHr = [
    new Day(
        "13. Rujna 2024",
        "Pozvani ste da dođete na piće s nama večer prije vjenčanja u Hotelu Palace! Opustite se nakon putovanja, upoznajte druge goste i pripremite se za vikend slavlja. Dođite i odlazite po volji! Naravno, ovo je opcionalno, ali nadamo se da ćemo vas vidjeti tamo! Dress code: uredno.",
        [
            new EventItem("Piće Dobrodošlice", "17:00-22:00", "Terasa Lenga, Hotelu Dubrovnik")
        ],
        [
            "https://dubrovnik-palace.hotelmix.co.uk/data/Photos/OriginalPhoto/12771/1277134/1277134621/Hotel-Dubrovnik-Palace-Exterior.JPEG",
            "https://dubrovnik-palace.at-hotels.com/data/Photos/OriginalPhoto/12771/1277134/1277134336/photo-palace-20000-dubrovnik-5.JPEG"
        ]
    ),
    new Day(
        "14. Rujna 2024",
        "Svi događaji na dan vjenčanja održat će se u Hotelu Dubrovnik Palace. Pogledajte stranicu “Smještaj” za više informacija! Napomena - sve lokacije unutar hotela su pješačke udaljenosti. Osoblje će voditi goste do svake lokacije i bit će dostupno kako bi podržalo goste koji zahtijevaju pristupačniju rutu. Dress code: formalno, formalno za plažu.",
        [
            new EventItem("Ceremonija", "16:30", "Terasa Lenga, Hotelu Dubrovnik"),
            new EventItem("Koktel Sat", "17:30", "Terasa Lenga, Hotelu Dubrovnik"),
            new EventItem("Svadbeni Prijam", "19:00", "Vala Beach Club, Hotelu Dubrovnik"),
            new EventItem("Disko", "23:30", "Noćni klub Orlandinjo, Hotelu Dubrovnik")
        ],
        [
            "https://images.squarespace-cdn.com/content/v1/5d67f54999e03b0001ecc78e/1594309883942-NZ3SQ4RFJSSRO6Y9M4QW/DJI_0047.jpg",
            "https://cdn.audleytravel.com/1050/749/79/7996227-hotel-dubrovnik-palace-dubrovnik.webp",
            "https://palace20000.thedubrovnikhotels.net/data/Imgs/OriginalPhoto/12771/1277134/1277134429/palace-20000-dubrovnik-img-6.JPEG",
            "https://palace20000.thedubrovnikhotels.net/data/Imgs/OriginalPhoto/12771/1277134/1277134852/palace-20000-dubrovnik-img-12.JPEG"
        ]
    ),
    new Day(
        "15. Rujna 2024.",
        "Pozivamo Vas da provedete dan nakon vjenčanja s nama na plaži! Imat ćemo privatne ležaljke i kabane tik uz more, uz hranu i piće. Dođite kad želite! Naravno, ovo je opcionalno, ali nadamo se da ćemo vas vidjeti tamo! Dress code: kostimi i pokrivala.",
        [
            new EventItem("Roštilj na plaži", "12:00", "Copacabana Beach")
        ],
        [
            "https://thebeachdubrovnik.com/cdn/shop/products/Copacabanabeachbaldahin.jpg?v=1659724370&width=1946",
            "https://thebeachdubrovnik.com/cdn/shop/files/Copacabanahome3.jpg?v=1658231088&width=1500",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/189524877.jpg?k=80fa68501f4915f002cfce4c59064620b06373283ed84ba1ae36c1474fbd4c1f&o=&hp=1"

        ]
    )
]

function getIcon(title) {
    switch (title) {
        case "Ceremony":
        case "Ceremonija":
            return (<FaRegGem />)
        case "Cocktail Hour":
        case "Koktel Sat":
            return (<FaCocktail />)
        case "Reception":
        case "Svadbeni Prijam":
            return (<GiPartyPopper />)
        case "Disco":
        case "Disko":
            return (<BsSunrise />)
        case "Beach BBQ":
        case "Roštilj na plaži":
            return (<TbBeach />)
        case "Welcome Drinks":
        case "Piće Dobrodošlice":
            return (<FaCocktail />)
        default:
            return (<FaRegGem />)
    }
}

function buildEvents(events) {
    return (
        <div className='column'>
            {events.map((event) => {
                return (
                    <div className='primary-border-full-selected item'>
                        <div className='row content-center'>
                            <IconContext.Provider value={{ color: "var(--steeny-brown)" }}>
                                <div className='column content-center icon'>
                                    {getIcon(event.title)}
                                </div>
                            </IconContext.Provider>
                            <div className='row padding-32 content-center width-full space-between'>
                                <h1 className='rem-3 primary text-center'><strong>{event.title}</strong></h1>
                                <div className='column'>
                                    <p className='rem-2 primary text-end'>{event.location}</p>
                                    <p className='rem-1_5 primary text-end'>{event.time}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

function buildDescription(description) {
    return (
        <div className='row center'>
            <p className='description'>{description}</p>
        </div>
    )
}

function buildImageRow(images, onClick) {
    return (
        <div className='row center'>
            <ImageSlider images={images} onImageClicked={(currentIndex) => onClick(images, currentIndex)} />
        </div>
    );
}

function WeddingEventsPage(props) {
    const [selectedImages, setSelectedImages] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    let itemsForUi = props.isCroatianEnabled ? itemsHr : items
    let ui = itemsForUi.map((day) => {
        return (
            <div className='column'>
                <FancyHeader headerName={day.name} />
                {buildDescription(day.description)}
                {buildEvents(day.events)}
                <div className='spacer-h-25' />
                {buildImageRow(day.images, (images, selectedIndex) => {
                    setSelectedImages(images);
                    setSelectedIndex(selectedIndex);
                })}
                <div className='spacer-h-50' />
            </div>
        );
    })
    return (
        <div className='page column'>
            {ui}
            {selectedImages !== null && <ScrollableOverlay paths={selectedImages} startIndex={selectedIndex} onClose={() => {
                setSelectedImages(null);
                setSelectedIndex(0);
            }} />}
        </div>
    );
}

export default WeddingEventsPage;