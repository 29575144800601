import React, { useEffect, useState, useCallback } from 'react';
import './GalleryPage.css';

import Overlay from '../overlays/Overlay';

const GalleryPage = ({ imageFilenames }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    console.log(`SELECTED INDEX: ${selectedIndex}`)
    if (selectedIndex == null) {
      setSelectedImage(null)
    } else {
      setSelectedImage(`/gallery/${imageFilenames[selectedIndex]}`)
    }
  }, [selectedIndex])

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'ArrowRight') {
      if (selectedIndex >= imageFilenames.length - 1) {
        setSelectedIndex(0)
      } else {
        setSelectedIndex(selectedIndex + 1)
      }
    } else if (event.key === 'ArrowLeft') {
      if (selectedIndex <= 0) {
        setSelectedIndex(imageFilenames.length - 1)
      } else {
        setSelectedIndex(selectedIndex - 1)
      }
    }
  });

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="page gallery">
      {imageFilenames.map((filename, index) => (
        <div className="gallery-item" key={index}>
          <div className="image-container content-center">
            <img src={`/gallery/${filename}`} alt={`Wedding Photo ${index}`} onClick={() => { setSelectedIndex(index) }} />
          </div>
        </div>
      ))}
      {selectedImage !== null && <Overlay path={selectedImage} onClose={() => { setSelectedIndex(null) }} />}
    </div>
  );
};

export default GalleryPage;
