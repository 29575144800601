import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import './App.css';
import NavItem from './models/NavItem'
import WeddingEventsPage from './components/page_events/WeddingEventsPage';
import HomePage from './components/page_home/HomePage';
import OurStory from './components/page_story/OurStory';
import Sidebar from './components/sidebar/Sidebar.js';
import FaqPage from './components/page_faq/FaqPage';
import RsvpPage from './components/page_rsvp/RsvpPage';
import AccommodationsPage from './components/page_accommodations/AccommodationsPage';
import ExtendYourTripPage from './components/page_extend/ExtendYourTripPage';
import GalleryPage from './components/page_gallery/GalleryPage';
import MobilePage from './components/mobile/MobilePage';

function getBody(selectedNavItem, isCroatianEnabled, galleryFileNames) {
  switch (selectedNavItem.index) {
    case 0:
      return (<HomePage isCroatianEnabled={isCroatianEnabled} />);
    case 1:
      return (<OurStory isCroatianEnabled={isCroatianEnabled} />);
    case 2:
      return (<WeddingEventsPage isCroatianEnabled={isCroatianEnabled} />);
    case 3:
      return (<AccommodationsPage isCroatianEnabled={isCroatianEnabled} />);
    case 4:
      return (<ExtendYourTripPage isCroatianEnabled={isCroatianEnabled} />);
    case 5:
      return (<FaqPage isCroatianEnabled={isCroatianEnabled} />);
    case 6:
      return (<RsvpPage />);
    case 7:
      return (<GalleryPage imageFilenames={galleryFileNames} />);
    default:
      return (<h1>Path is not defined!</h1>);
  }
}

function App() {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [isCroatianEnabled, setIsCroatianEnabled] = useState(false);
  const [imageFilenames, setImageFilenames] = useState([]);
  const [bypassMobile, setBypassMobile] = useState(false);

  let navItemTitles = ["Home", "Our story", "Wedding events", "Accommodations", "Extend your trip", "FAQ", "RSVP", "Gallery"]
  let navItemTitlesCroatian = ["Početna", "Naša priča", "Vjenčanje", "Smještaj", "Produljite svoje putovanje", "Često postavljena pitanja", "Potvrdite prisustvovanje", "Galerija"]
  let navItemsForUi = isCroatianEnabled ? navItemTitlesCroatian : navItemTitles
  let navItems = navItemsForUi.map((title, index) => {
    return new NavItem(title, index, index === selectedItemIndex)
  })

  useEffect(() => {
    // Fetch image filenames from the 'images' directory
    fetch('/data/gallery_file_names.json')
      .then((response) => response.json())
      .then((data) => setImageFilenames(data.sort(() => Math.random() - 0.5)));
  }, []);

  const updateSelectedItem = (selectedItem) => {
    setSelectedItemIndex(selectedItem.index);
  }

  const updateCroatianEnabled = (isCroatianEnabled) => {
    setIsCroatianEnabled(isCroatianEnabled);
  }

  if (window.innerWidth < 768 && !bypassMobile) {
    return (
      <MobilePage proceedAnyway={() => { setBypassMobile(true) }} />
    );
  }

  let selectedNavItem = navItems.find(function (item) { return item.isSelected })
  let name = bypassMobile ? 'app-mobile' : 'app'
  return (
    <div className={name}>
      <Sidebar
        items={navItems}
        onClick={(item) => updateSelectedItem(item)}
        onCheckboxClick={(isCroatianEnabled) => updateCroatianEnabled(isCroatianEnabled)}
        isCroatianEnabled={isCroatianEnabled}
      />
      <div>{getBody(selectedNavItem, isCroatianEnabled, imageFilenames)}</div>
    </div>
  );
}

export default App;