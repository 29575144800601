import React, { useEffect, useState } from 'react';

import FancyHeader from "../FancyHeader";
import ImageSlider from "../image_slider/ImageSlider";
import ScrollableOverlay from '../overlays/ScrollableOverlay';

import './ExtendYourTripPage.css';

class ExtendItem {
    constructor(title, description, images) {
        this.title = title;
        this.description = description;
        this.images = images;
    }
}

class Section {
    constructor(title, items) {
        this.title = title;
        this.items = items;
    }
}

function convertJSONToSections(data) {
    const sections = [];

    data.forEach(jsonSection => {
        const title = jsonSection.title;
        const items = jsonSection.items.map(jsonItem => {
            const item = new ExtendItem(
                jsonItem.title,
                jsonItem.description,
                jsonItem.images
            );
            return item;
        });

        const section = new Section(title, items);
        sections.push(section);
    });

    return sections;
}

async function getSectionsFromFile(isCroatianEnabled) {
    try {
        let filename = isCroatianEnabled ? "/data/extend_your_trip_hr.json" : "/data/extend_your_trip.json"
        const response = await fetch(filename); // Replace with the correct path to your JSON file
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        const sections = convertJSONToSections(data);
        return sections;
    } catch (error) {
        console.error('Error reading data from JSON file:', error);
        return [];
    }
}

function buildRow(extendItem, onClick) {
    const totalParagraphs = extendItem.description.split('\n')
    const description = totalParagraphs.map((paragraph, index) => (
        <div className='column'>
            <p key={index} className='rem-1_5 primary'>{paragraph}</p>
            {index !== totalParagraphs.length - 1 && <div className='spacer-h-16' />}
        </div>
    ));
    return (
        <div className='row padding-16'>
            <ImageSlider images={extendItem.images} onImageClicked={(currentIndex) => onClick(extendItem.images, currentIndex)} />
            <div className='spacer-w-64' />
            <div className='column'>
                <p className='rem-3 primary'>{extendItem.title}</p>
                <div className='spacer-h-10' />
                {/* <p className='rem-1_5 primary'>{extendItem.description}</p> */}
                {description}
            </div>
        </div>
    );
}

const ExtendYourTripPage = (props) => {

    const [sections, setSections] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sectionsData = await getSectionsFromFile(props.isCroatianEnabled);
                setSections(sectionsData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // The empty dependency array ensures this effect runs only once

    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedIndex,  setSelectedIndex] = useState(0)
    const selectImages = (images, currentIndex) => {
        setSelectedIndex(currentIndex)
        setSelectedImages(images)
    }
    
    let pageUi = sections.map((section) => {
        return (
            <div className='column'>
                <FancyHeader headerName={section.title} />
                {section.items.map((item) => {
                    return (
                        <div key={item.title}>
                            {buildRow(item, (images, currentIndex) => selectImages(images, currentIndex))}
                            {/* {itemIndex !== section.items.length - 1 && <div className='column-divider' />} */}
                        </div>
                    );
                })}
            </div>
        );
    })

    let title = props.isCroatianEnabled ? "Tko može pomoći u organizaciji međunarodnih putovanja?" : "Who can help support international travel plans?"
    let description = props.isCroatianEnabled ? "Za one koji nisu upoznati s putovanjem u Hrvatsku ili razmatraju produženje putovanja, slobodno se obratite našem putničkom agentu, Miroslavu Atanasovu. Možete ga kontaktirati na support@flights365.com. Korištenje ove usluge je besplatno." : "For those unfamiliar with traveling to/within Croatia or looking to extend travel plans, feel free to contact our travel agent, Miroslav Atanasov. He can be reached at support@flights365.com. There is no cost for utilizing this service."

    return (
        <div className='page'>
            <div className='column'>
                {pageUi}
                <div className='spacer-h-25'/>
                <h1 className='primary text-center'>{title}</h1>
                <div className='spacer-h-10'/>
                <h3 className='primary text-center'>{description}</h3>
                <img className='img-map' src="./img/croatia_map.png" alt="Croatia map" />
                {selectedImages.length > 0 && <ScrollableOverlay paths={selectedImages} startIndex={selectedIndex} onClose={() => { selectImages([], 0) }} />}
            </div>
        </div>
    );
}

export default ExtendYourTripPage;