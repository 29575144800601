import './RoundedImage.css'

function RoundedImage(props) {

    return (
        <div className="Rounded">
            <img src={props.url} alt={props.alt} onClick={props.onImageClicked}/>
        </div>
    );
}

export default RoundedImage;