// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { Observable } from 'rxjs';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB9FNp07aKnWH9UbPZFNpT9E9x5VuVwtJo",
    authDomain: "wedding-website-75451.firebaseapp.com",
    projectId: "wedding-website-75451",
    storageBucket: "wedding-website-75451.appspot.com",
    messagingSenderId: "443871429302",
    appId: "1:443871429302:web:ef78933ac31fb58a61dc17",
    measurementId: "G-5WKC85CRC4"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);

export function updateRsvp(rsvpItem) {
    set(ref(database, 'rsvp/' + rsvpItem.name),
        {
            isGoing: rsvpItem.isGoing,
            email: rsvpItem.email,
            diet: rsvpItem.diet,
            menu: rsvpItem.selectedMenu,
            rsvpStatus: rsvpItem.rsvpStatus
        }
    );
}

export const RsvpItem = class {
    constructor(name, isGoing, email, diet, selectedMenu, rsvpStatus) {
        this.name = name;
        this.isGoing = isGoing;
        this.email = email;
        this.diet = diet;
        this.selectedMenu = selectedMenu;
        this.rsvpStatus = rsvpStatus;
    }

    getEmailForDialog() {
        return this.email.length > 0 ? this.email : ""
    }

    getDietaryRestrictionsForDialog() {
        return this.diet.length > 0 ? this.diet : ""
    }

    getEmailPreview() {
        return this.email.length > 0 ? "Submitted!" : "Optional"
    }

    getDietaryRestrictionsPreview() {
        return this.diet.length > 0 ? "Submitted!" : "Optional"
    }
}

export const rsvpDataObservable = new Observable((subscriber) => {

    const rsvpRef = ref(database, 'rsvp');
    onValue(rsvpRef, (snapshot) => {
        var returnArr = [];
        snapshot.forEach(function (childSnapshot) {
            var item = childSnapshot.val();
            var data = new RsvpItem(childSnapshot.key, item.isGoing, item.email, item.diet, item.menu, item.rsvpStatus);
            returnArr.push(data);
        });
        subscriber.next(returnArr)
    });

    // Complete the observable
    // subscriber.complete();
});