import React from 'react';

import './StarRating.css'

const StarRating = ({ rating }) => {
  // Ensure the rating is within the valid range (1 to 5)
  const sanitizedRating = Math.min(Math.max(rating, 1), 5);

  // Create an array to hold the star icons
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    // Determine whether to show a filled or empty star based on the current index and rating
    const starClassName = i <= sanitizedRating ? 'star-filled' : 'star-empty';
    stars.push(<span key={i} className='star'>
        {i <= sanitizedRating ? '★' : '☆'}
    </span>);
  }

  return (
    <div className="star-rating">
      {stars}
    </div>
  );
};

export default StarRating;