import React, { useState, useEffect } from 'react';
import { updateRsvp, rsvpDataObservable, RsvpItem } from '../../firebase/FirebaseClient';
import Overlay from './../overlays/Overlay';
import menu_fish from './../../assets/dentex.png'
import menu_meat from './../../assets/meat.png'
import menu_vegetarian from './../../assets/vege.png'

import '../../App.css'
import './RsvpPage.css'
import Dialog from '../dialog/Dialog';
import RadioButton from '../radio_button/RadioButton';
import RsvpDropdown from '../rsvp_dropdown/RsvpDropdown';

function RsvpPage() {
    var rsvpData = [];
    rsvpDataObservable.subscribe({
        next: (value) => {
            rsvpData = value;
            performSearch();
        },
        error: (error) => console.error(error),
        complete: () => console.log('Complete')
    })

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [pdfOverlayPath, setPdfOverlayPath] = useState('');
    const [selectedRsvpItem, setSelectedRsvpItem] = useState(null);
    const [showEmailDialog, setShowEmailDialog] = useState(false);
    const [showDietDialog, setShowDietDialog] = useState(false);

    useEffect(() => {
        performSearch();
    }, [searchQuery]);

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const closeOverlay = () => {
        setPdfOverlayPath("")
    }

    const performSearch = () => {
        if (searchQuery.trim().length === 0) {
            setSearchResults([]);
            return;
        }

        var filteredData = rsvpData.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        ).sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        setSearchResults(filteredData);
    };

    var displayResults = searchResults.map((rsvpItem) => {
        return (
            displaySearchResult(
                rsvpItem,
                function (updatedRsvpItem) { updateRsvp(updatedRsvpItem) }, // udpate firebase
                function (pathToPdf) { setPdfOverlayPath(pathToPdf) }, // handle menu selection
                function () { // handle email dialog presentation
                    setSelectedRsvpItem(rsvpItem)
                    setShowEmailDialog(true)
                }, function () { // handle diet dialog presentation
                    setSelectedRsvpItem(rsvpItem)
                    setShowDietDialog(true)
                }
            )
        );
    })

    const updateEmail = (email) => {
        let updatedRsvpItem = { ...selectedRsvpItem, email: email };
        updateRsvp(updatedRsvpItem);
        setSelectedRsvpItem(null);
        setShowEmailDialog(false);
    };

    const updateDiet = (diet) => {
        let updatedRsvpItem = { ...selectedRsvpItem, diet: diet };
        updateRsvp(updatedRsvpItem);
        setSelectedRsvpItem(null);
        setShowDietDialog(false);
    };

    const cancelDialog = () => {
        setShowDietDialog(false)
        setShowEmailDialog(false)
        setSelectedRsvpItem(null)
    };

    return (
        <div className="page column">
            <h1 className='primary'>RSVP Deadline: June 10, 2024 (hotel block valid until June 13)</h1>
            <h3 className='primary'>Please note: answers are recorded automatically. If you have questions, please contact us at dcwedding2925@gmail.com</h3>
            <div className='spacer-h-25' />
            {registryRow()}
            <div className='spacer-h-50' />
            <input type="text" className="searchBar" value={searchQuery} onChange={handleInputChange} placeholder="Search for your party" />
            <div className='spacer-h-25' />
            <div className="results">
                <ul>
                    {displayResults}
                </ul>
            </div>
            {pdfOverlayPath.trim().length !== 0 && <Overlay path={pdfOverlayPath} onClose={closeOverlay} />}
            {showEmailDialog && selectedRsvpItem !== null &&
                <Dialog
                    title="Contact email"
                    description="If you want to receive wedding information updates or be notified when new things are added to the site, give us an email we can reach you at. We will not share or sell this information with anyone. Be on the lookout for emails from dcwedding2925@gmail.com"
                    onCancel={cancelDialog}
                    onSubmit={(email) => updateEmail(email)}
                    initialValue={selectedRsvpItem.getEmailForDialog()} />
            }
            {showDietDialog && selectedRsvpItem !== null &&
                <Dialog
                    title="Dietary restrictions"
                    description="Please list any restrictions or additional preferences you may have for your dining experience. We will share information with the event staff to ensure you are properly accommodated."
                    onCancel={cancelDialog}
                    onSubmit={(diet) => updateDiet(diet)}
                    initialValue={selectedRsvpItem.getDietaryRestrictionsForDialog()} />
            }
        </div>
    );
}

function item(title, description, onClick) {
    return (
        <div className='column content-center center rsvpExpandedItemCell' onClick={() => onClick()}>
            <h2 className='primary'>{title}</h2>
            <h4><i>{description}</i></h4>
        </div>
    );
}

function registryRow() {
    return (
        <div className='column'>
            <h1 className='primary'>Registry can be found <a href="https://registry.theknot.com/--september-2024-croatia/65763361" target="_blank">here</a></h1>
            <h3 className='primary'>We recognize that this is not a small trip, so please do not feel obligated to give a gift. This is primarily for those who are feeling extra generous and so we can buy items ourselves at 20% off after the wedding (lol)</h3>
        </div>
    );
}

function menuSection(currentSelectedMenu, onMenuSelected, onMenuClicked) {
    const noSelection = currentSelectedMenu === ""
    const menuName = noSelection ? "None" : currentSelectedMenu.charAt(0).toUpperCase() + currentSelectedMenu.slice(1);
    return (
        <div className='row content-center center space-evenly'>
            <div className='column'>
                <h1 className='primary'>Menu selection</h1>
                <div className='spacer-h-10' />
                <h2>{menuName}</h2>
            </div>
            <div className='row'>
                {menuSectionItem(currentSelectedMenu === "fish", menu_fish, () => onMenuSelected("fish"), () => onMenuClicked(menu_fish))}
                <div className='spacer-w-16' />
                {menuSectionItem(currentSelectedMenu === "meat", menu_meat, () => onMenuSelected("meat"), () => onMenuClicked(menu_meat))}
                <div className='spacer-w-16' />
                {menuSectionItem(currentSelectedMenu === "vegetarian", menu_vegetarian, () => onMenuSelected("vegetarian"), () => onMenuClicked(menu_vegetarian))}
            </div>
            <h4><i>* Required</i></h4>
        </div>
    );
}

function menuSectionItem(isSelected, menuUrl, onSelect, onClick) {
    const itemClass = isSelected ? 'menuImageSelected' : 'menuImage';
    return (
        <div className='column content-center'>
            <img className={itemClass} src={menuUrl} alt={"menu"} onClick={() => onClick()} />
            <div className='spacer-h-5' />
            <RadioButton checked={isSelected} onChange={() => onSelect()} />
        </div>
    );
}

function displaySearchResult(rsvpItem, updateCallback, showPdfOverlay, showEmailDialog, showDietDialog) {
    let isGoing = rsvpItem.rsvpStatus === 1

    const updateRsvpState = (value) => {
        let updatedRsvpItem = { ...rsvpItem, rsvpStatus: value };
        updateCallback(updatedRsvpItem);
    };

    let currentMenuSelection = rsvpItem.selectedMenu
    const updateRSVPMenu = (menuName) => {
        let updatedRsvpItem = { ...rsvpItem, selectedMenu: menuName };
        updateCallback(updatedRsvpItem);
    }

    return (
        <div className="column rsvpItem primary-border-full-selected" key={rsvpItem.name}>
            <div className='row content-center rsvpHeader'>
                <h1 className='rem-3 primary'><strong>{rsvpItem.name}</strong></h1>
                <RsvpDropdown initialState={rsvpItem.rsvpStatus} onChangeCallback={updateRsvpState} />
            </div>

            {isGoing &&
                <div className='column'>
                    <div className='spacer-h-25' />
                    {menuSection(currentMenuSelection, (menuSelection) => updateRSVPMenu(menuSelection), (path) => showPdfOverlay(path))}
                    <div className='spacer-h-25' />

                    {currentMenuSelection !== "" &&
                        <div className='column'>
                            <div className='column-divider' />
                            <div className='spacer-h-25' />
                            <div className='row space-evenly'>
                                {item("Dietary restrictions", rsvpItem.getDietaryRestrictionsPreview(), () => showDietDialog())}
                                {item("Contact email", rsvpItem.getEmailPreview(), () => showEmailDialog())}
                            </div>
                        </div>
                    }
                </div>
            }

        </div>
    );
}

export default RsvpPage;