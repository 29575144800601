import FancyHeader from "../FancyHeader";

const Section = class {
    constructor(title, faqs) {
        this.title = title;
        this.faqs = faqs;
    }
}

const Faq = class {
    constructor(question, answer) {
        this.question = question;
        this.answer = answer;
    }
}

let sections = [
    new Section(
        "Important Wedding Information",
        [
            new Faq("What is the attire?", "Please wear formal attire. Beach formal is acceptable. This includes suits and mid to long length dresses. Dress to impress but above all, be comfortable!"),
            new Faq("What to do while at the venue hotel (Hotel Dubrovnik Palace)?", "Aside from enjoying the pools, seaside lounging, restaurants and drinks, there is a shuttle that runs in a loop from the hotel (and many of the surrounding hotels) to the \"old city\", for just a few Euros."),
            new Faq("Any special attire considerations?", "Because the reception occurs on natural stone, the ground may be uneven. It is recommended that block heels be worn in place of stilettos. Additionally, expect a mild breeze coming off the sea, so consider an outer layer!"),
            new Faq("Will the venue be covered?", "The ceremony at Lenga Terrace will be outdoor, but covered. The reception at Vala Beach Club will not be covered, though there is an indoor back-up should we need it."),
            new Faq("Do all guests need to stay at the venue hotel (Hotel Dubrovnik Palace)?", "While staying at the venue hotel may be most convenient, guests are free to stay at any hotel they choose. If staying at Hotel Dubrovnik Palace or Hotel Kompas, guests can use our discount code NikoMala. Many hotels are within walking distance to each other or accessible by car.")
        ]
    ),
    new Section(
        "Questions about International Travel",
        [
            new Faq("Do guests need a visa to travel to Croatia?", "As of now (May 2024), no visa is required until 2025. We will provide an update here if anything changes."),
            new Faq("Which airport is closest?", "There is an international airport in the city of Dubrovnik, approximately 10 miles from the venue. There is a direct flight between Newark, NJ and Dubrovnik. Guests can also consider the nearby Split airport, which is also well connected to many European cities."),
            new Faq("What is the best way to travel throughout Croatia?", "Croatia is very easily traveled via bus, boat, or rental car. Schedules and tickets can often be found online in advance, or in person at transportation terminals."),
            new Faq("Who can help support international travel plans?", "For those unfamiliar with traveling to/within Croatia or looking to extend travel plans, feel free to contact our travel agent, Miroslav Atanasov. He can be reached at support@flights365.com. There is no cost for utilizing this service. Please see the \"Extend your trip\" page for some inspiration!"),
        ]
    ),
    new Section(
        "Questions about Croatia",
        [
            new Faq("What is the weather in Croatia?", "Croatia typically has very mild weather in September. Temperatures typically range from 65 Fahrenheit/18 Celsius to 77 Fahrenheit/25 Celsius. Please be mindful that it will likely be breezy along the sea at night. Even if the outside temperature is a bit cool, the sea temperature remains very warm and swimmable."),
            new Faq("What language is spoken in Croatia?", "The official language of Croatia is Croatian. Most Croatians are multi-lingual, with many speaking English fluently."),
            new Faq("What currency is used in Croatia?", "Croatia is a member of the European Union and uses the Euro."),
            new Faq("Is Uber available in Croatia?", "Uber is available and quite affordable in Croatia. Taxis are also popular and can be found at any airport or major transporation hub.")
        ]
    )
]

let sectionsCroatian = [
    new Section(
        "Važne informacije o vjenčanju",
        [
            new Faq("Koja je odijevanje?", "Molimo vas nosite formalnu odjeću. Formalna odjeća primjerena plaži je prihvatljiva. To uključuje odijela i haljine srednje do duge duljine. Odjenite se elegantno, ali prije svega budite udobni!"),
            new Faq("Što raditi dok ste u hotelu mjesta događaja (Hotel Dubrovnik Palace)?", "Osim uživanja u bazenima, ležaljkama uz more, restoranima i pićima, dostupan je shuttle koji vozi u krug od hotela (i mnogih okolnih hotela) do \"starog grada\", za samo nekoliko eura."),
            new Faq("Ima li posebnih napomena za odjeću?", "Budući da se prijam održava na prirodnom kamenju, tlo može biti neujednačeno. Preporučuje se nošenje cipela s blok petom umjesto štikli. Osim toga, očekujte blagu povjetarac s mora, pa razmislite o vanjskom sloju!"),
            new Faq("Hoće li mjesto biti pokriveno?", "Ceremonija na terasi Lenga bit će na otvorenom, ali pokrivena. Prijam u Vala Beach Clubu neće biti pokriven, iako postoji unutarnja alternativa ako bude potrebno."),
            new Faq("Mora li svaki gost odsjesti u hotelu mjesta događaja (Hotel Dubrovnik Palace)?", "Iako je boravak u hotelskom kompleksu možda najpraktičniji, gosti su slobodni odabrati bilo koji hotel. Ako odsjedaju u Hotelu Dubrovnik Palace ili Hotelu Kompas, gosti mogu koristiti naš promotivni kod NikoMala. Mnogi hoteli su u pješačkoj udaljenosti jedan od drugog ili dostupni automobilom.")
        ]
    ),
    new Section(
        "Pitanja o međunarodnom putovanju",
        [
            new Faq("Treba li gostima viza za putovanje u Hrvatsku?", "Trenutno (svibanj 2024.), viza nije potrebna do 2025. Obavijestit ćemo ovdje ako se nešto promijeni."),
            new Faq("Koji je najbliži aerodrom?", "Postoji međunarodna zračna luka u gradu Dubrovniku, udaljena otprilike 10 milja od mjesta događanja. Postoji izravni let između Newarka, NJ i Dubrovnika. Gosti također mogu razmotriti obližnju zračnu luku Split, koja je također dobro povezana s mnogim europskim gradovima."),
            new Faq("Koji je najbolji način putovanja po Hrvatskoj?", "Hrvatska se vrlo lako putuje autobusom, brodom ili najmom automobila. Rasporedi i karte često se mogu pronaći online unaprijed ili osobno na prijevoznim terminalima."),
            new Faq("Tko može pomoći u podršci za međunarodne putne planove?", "Za one koji nisu upoznati s putovanjem u/unutar Hrvatske ili koji žele produžiti putne planove, slobodno kontaktirajte našeg putničkog agenta, Miroslava Atanasova. Možete ga kontaktirati na support@flights365.com. Nema troškova za korištenje ove usluge. Molimo pogledajte stranicu \"Produljite svoje putovanje\" za malo inspiracije!")
        ]
    ),
    new Section(
        "Pitanja o Hrvatskoj",
        [
            new Faq("Kakvo je vrijeme u Hrvatskoj?", "Hrvatska obično ima vrlo blagu klimu u rujnu. Temperature obično variraju od 65 stupnjeva Fahrenheita/18 Celzijevih stupnjeva do 77 stupnjeva Fahrenheita/25 Celzijevih stupnjeva. Imajte na umu da će vjerojatno biti vjetrovito uz more noću. Čak i ako je vani malo hladno, temperatura mora ostaje vrlo topla i prikladna za plivanje."),
            new Faq("Koji jezik se govori u Hrvatskoj?", "Službeni jezik Hrvatske je Hrvatski. Većina Hrvata govori više jezika, pri čemu mnogi tečno govore engleski."),
            new Faq("Koja se valuta koristi u Hrvatskoj?", "Hrvatska je članica Europske Unije i koristi Euro."),
            new Faq("Je li dostupan Uber u Hrvatskoj?", "Uber je dostupan i prilično pristupačan u Hrvatskoj. Također su popularni taksiji i mogu se pronaći na svakom aerodromu ili većem prijevoznom čvorištu.")
        ]
    )
]

function buildFaqs(faqs) {
    return (
        <div className="column">
            {faqs.map((faq) => {
                return (
                    <div className='column'>
                        <h1 className='rem-2 primary'><strong>{faq.question}</strong></h1>
                        <p className='rem-1_5 primary'>{faq.answer}</p>
                        <div className='spacer-h-50' />
                    </div>
                );
            })}
        </div>
    )
}

function FaqPage(props) {
    let uiSections = props.isCroatianEnabled ? sectionsCroatian : sections
    let ui = uiSections.map((section) => {
        return (
            <div className='column'>
                <FancyHeader headerName={section.title} />
                {buildFaqs(section.faqs)}
                <div className='spacer-h-50' />
            </div>
        );
    })

    return (
        <div className='page column'>
            {ui}
        </div>
    );
}

export default FaqPage;