import './Checkbox.css'

function Checkbox({ label, checked, onChange }) {
    return (
        <label className="checkbox">
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
            />
            {label}
        </label>
    );
}

export default Checkbox;